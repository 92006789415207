import { useEffect, useState } from "react";

export function usePagination(data, perpage) {
  const [paginations, setPagination] = useState({
    page: 0,
    perpage,
    total: 0,
  });

  useEffect(() => {
   
    setPagination((prev) => ({
      page: 0,
      perpage: prev.perpage,
      total: Math.ceil(data.length / paginations.perpage),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

 

  return [paginations, setPagination];
}
