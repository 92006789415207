import { Table, Image, Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import LineChart from "../chart/index.jsx";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker.jsx";

function getEvolution(oldP, newP) {
  if (typeof newP == "string"){
	  newP = parseFloat(newP).toFixed(2);
  }
  if (typeof oldP == "string"){
	  oldP = parseFloat(oldP).toFixed(2);
  }
  let percDiff = (100 - (oldP / newP) * 100).toFixed(10);
  return (
    (percDiff > 0 ? "- " : "+ ") +
    Math.abs(percDiff).toFixed(2) +
    (percDiff > 0 ? "% \u2193" : "% \u2191")
  );
}
function getBlackStars(stars) {
  let blacks = "";
  while (stars > 0) {
    blacks = blacks + "\u2605";
    stars--;
  }
  return blacks;
}

function getChartSymbol(oldP, newP) {
  let percDiff = (100 - (oldP / newP) * 100).toFixed(10);
  return percDiff > 0 ? "📉" : "📈";
}

function getWhiteStars(stars) {
  let rimind = 0;
  if (stars < 5) rimind = 5 - stars;
  let whites = "";
  while (rimind > 0) {
    whites = whites + "\u2606";
    rimind--;
  }
  return whites;
}

function TableHeader({ l, onClickSort, liveSearch, statu }) {
	
  return (
    <thead>
      <tr>
        <th>
          <input
            type="text"
            placeholder="filter here..."
            onChange={liveSearch("brand")}
          />
        </th>
        <th></th>
        <th>
          <input
            type="text"
            placeholder="filter here..."
            onChange={liveSearch("name")}
          />
        </th>
        <th></th>
        {statu === "changed" ? (
          <>
            <th></th>
            <th></th>
          </>
        ) : null}
        <th></th>
      </tr>
      <tr>
        <th>brand</th>
        <th>image</th>
        <th>name</th>
        {statu === "new" ? <></> : null}
        {statu === "changed" ? (
          <>
            <th className="col-md-1">old price</th>
            <th className="col-md-1">new price</th>
            <th className="col-md-2">evolution <Button onClick={()=> onClickSort()} style={{ width: '50px' }}>{"\u21C5"}</Button> </th>
            <th>price History</th>
          </>
        ) : (
          <th className="col-md-2">price</th>
        )}
        <th>ratings</th>
      </tr>
    </thead>
  );
}

function TableRow({ l, onClickImage, storePrefix, statu }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const gaEventTracker = useAnalyticsEventTracker("table");
  const currentDate = new Date().toISOString().slice(0, 10);
  const priceHistoryKeys = Object.keys(l.priceHistory);
  const beforeLastKey = priceHistoryKeys[priceHistoryKeys.length - 2];
  const oldPrice = l.priceHistory[beforeLastKey];
  const [userData] = useState({
    labels: Object.keys(l.priceHistory),
    datasets: [
      {
        label: "Price",
        data: Object.values(l.priceHistory),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  });
  return (
    <>
      <tr>
        <td>{l.brand}</td>
        <td className="p-0">
          <Image
            src={
              l.image.startsWith(storePrefix) ? l.image : (l.image.startsWith("https://cdn.notinoimg.com/") ? l.image : (l.image.startsWith("https://d3p7vvhesu6og1.cloudfront.net") ? l.image : (l.image.startsWith("https://i.makeup.fr") ? l.image : (l.image.startsWith("https://www.parfumdo.com") ? l.image : (l.image.startsWith("https://m.media-amazon.com") ? l.image : storePrefix + l.image)))))
            }
            height={50}
            loading={"lazy"}
            onClick={onClickImage}
          ></Image>
        </td>
        <td>
          {l.name} -{" "}
          <a
            onClick={() => gaEventTracker("click on a link")}
            target="_blank"
            rel="noreferrer"
			href={
				l.url.startsWith("https://www.mademoiselle-bio.com") ? l.url : (l.url.startsWith("https://makeup.fr") ? l.url : (l.url.startsWith("https://www.parfumdo.com") ? l.url : (l.url.startsWith("https://www.amazon.fr") ? l.url : storePrefix + l.url)))
				}
          >
            click
          </a>
        </td>
        {statu === "changed" ? (
          <>
            {" "}
            <td>
              {oldPrice ? oldPrice + " €" : ""}
            </td>
            <td>{l.price + " €"}</td>
            <td>
              {oldPrice
                ? getEvolution(l.price, oldPrice)
                : "change 'state' filter"}
            </td>
            <td>
              <Button
                variant="primary"
                onClick={() => {
                  handleShow();
                  gaEventTracker("open a chart");
                }}
              >
                {getChartSymbol(l.price, l.oldPrice)}
              </Button>
            </td>
          </>
        ) : (
          <td>{l.price + " €"}</td>
        )}
        <td>
          {l.stars !== "none"
            ? getBlackStars(l.stars) + getWhiteStars(l.stars)
            : "\u2606\u2606\u2606\u2606\u2606"}
        </td>
      </tr>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <LineChart chartData={userData} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export function ProdsTable({
  liveSearch,
  data,
  paginations,
  onClickImage,
  store,
  statu,
 
}) {
	 const [sortDirection, setsortDirection] = useState(false);
	 // false small to bigger
	 const onClickSort =()=> {
		 setsortDirection (!sortDirection) 
	 }
  return (
    <Table striped bordered hover>
      <TableHeader onClickSort={onClickSort} liveSearch={liveSearch} statu={statu} />
      <tbody>
        {data
		  .sort((a,b)=>{const fa = a.brand.toLowerCase();
const fb = b.brand.toLowerCase();

const aPriceHistory = Object.values(a.priceHistory);
const bPriceHistory = Object.values(b.priceHistory);

let aEvolution, bEvolution;

if (aPriceHistory.length < 2) {
  aEvolution = (a.price - a.oldPrice) / a.oldPrice * 100;
} else {
  aEvolution = (aPriceHistory[aPriceHistory.length - 1] - aPriceHistory[aPriceHistory.length - 2]) / aPriceHistory[aPriceHistory.length - 2] * 100;
}

if (bPriceHistory.length < 2) {
  bEvolution = (b.price - b.oldPrice) / b.oldPrice * 100;
} else {
  bEvolution = (bPriceHistory[bPriceHistory.length - 1] - bPriceHistory[bPriceHistory.length - 2]) / bPriceHistory[bPriceHistory.length - 2] * 100;
}

if (aEvolution !== bEvolution) {
  if (aEvolution > bEvolution) {
    return sortDirection ? 1 : -1;
  }
  if (aEvolution < bEvolution) {
    return sortDirection ? -1 : 1;
  }
} else {
  if (fa !== fb) {
    return fa.localeCompare(fb) * (sortDirection ? 1 : -1);
  }
}

return 0;})
          .slice(
            paginations.page * paginations.perpage,
            paginations.perpage * (paginations.page + 1)
          )
          .map((l, i) => (
            <TableRow
              key={`product-${l.id}`}
              l={l}
              onClickImage={onClickImage}
              storePrefix={store.prefix}
              statu={statu}
            />
          ))}
      </tbody>
    </Table>
  );
}
