import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import AWS from "aws-sdk";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Button, Form, Nav, Modal, Navbar } from "react-bootstrap";
import Login from "./components/login";
import Products from "./components/products";
import ProtectedRoute from "./components/protectedRoute";
import { isAuthenticated } from "./components/protectedRoute";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker.jsx";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga";

const cookies = new Cookies();
const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
AWS.config.update({ region: "us-east-1" });
const TRACKING_ID = process.env.REACT_APP_GTM_ID;
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [loggedIn, setLoggedIn] = useState(isAuthenticated());
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function goLogout() {
    localStorage.removeItem("token");
    window.location.reload();
  }
  const getJSON = async (url) => {
    const response = await fetch(url);
    if (!response.ok)
      // check if response worked (no 404 errors etc...)
      throw new Error(response.statusText);

    const data = response.json(); // get JSON from the response
    return data; // returns a promise, which resolves to this data value
  };
  function removeme() {
    getJSON("https://scrfiles.s3.amazonaws.com/emailsDict.json")
      .then((data2) => {
        const token = cookies.get("usrEmailbeauty");
        for (let i = 0; i < data2.length; i++) {
          if (data2[i].hasOwnProperty(token)) data2.splice(i, 1);
        }
        let dict = JSON.stringify(data2);
        var buf = Buffer.from(dict);
        const s3 = new AWS.S3({
          accessKeyId: ACCESS_KEY_ID,
          secretAccessKey: SECRET_ACCESS_KEY,
        });
        var data = {
          Bucket: "scrfiles",
          Key: "emailsDict.json",
          Body: buf,
          ContentEncoding: "base64",
          ContentType: "application/json",
          ACL: "public-read",
        };

        s3.upload(data, function (err) {
          if (err) {
            console.log("");
          } else {
            goLogout();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    setLoggedIn(isAuthenticated);
  }, [loggedIn]);
  const gaEventTracker = useAnalyticsEventTracker("logout");
  const gaEventTracker2 = useAnalyticsEventTracker("unsubscribe");
  return (
    <Router>
      <div className="App">
        <nav className="navbar navbar-expand-sm navbar-light fixed-top">
          <div className="container">
            <Link className="navbar-brand" to={"/"}>
              <img
                src="https://scrfiles.s3.amazonaws.com/logo.png"
                alt="logo"
                className="photo"
              />
              <div id="logo">Beauty Bench</div>
            </Link>
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto my-2 my-lg-0"></Nav>
              <Form className="d-flex">
                {!loggedIn ? null : (
                  <Button
                    className="mr-2"
                    variant="outline-danger"
                    onClick={handleShow}
                  >
                    Unsubscribe
                  </Button>
                )}
                {!loggedIn ? null : (
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      goLogout();
                      gaEventTracker("logout");
                    }}
                  >
                    Logout
                  </Button>
                )}
              </Form>
            </Navbar.Collapse>
          </div>
        </nav>

        <div className="outer">
          <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Are you sure you want to unsubscribe?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleShow();
                  gaEventTracker2("unsubscribe");
                  removeme();
                }}
              >
                Yes, unsubscribe
              </Button>
            </Modal.Footer>
          </Modal>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute redirectTo="/login">
                  <Products />
                </ProtectedRoute>
              }
            />
            <Route
              path="/login"
              element={<Login callOnLogin={setLoggedIn} />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
