import { Row, Col } from "react-bootstrap";
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker.jsx';

export  default function StatuFilter({ setStatuFilter, statu }) {
	const gaEventTracker = useAnalyticsEventTracker('state');
  return (
    <>
      <Row>
        <Col className="col-2">
          <h5> state filter : </h5>
        </Col>
        <Col>
          <select onChange={(e) => {setStatuFilter(e.target.value); gaEventTracker('change the state');}} defaultValue={statu} className="col-md-6">
            <option value="changed">Changed</option>
			<option value="new">New</option>
          </select>
        </Col>
      </Row>
    </>
  );
}
