import { Row, Col } from "react-bootstrap";
import MultiSelectDropdown from "../../MultiSelectDropdown";

export default function CategoryFilter({categories, store, setCatFilter}) {
  return (
    <>
      <Row>
        <Col className="col-2">
          <h5> categories filter : </h5>
        </Col>
        <Col>
          <MultiSelectDropdown
            allOptions={store.name === "sephora.fr" ? [
              ...Array.from(new Set(categories)).map((l, i) => ({
                label: l,
                  value: l.replace(store.prefix, "")
              })),
            ] : ( store.name === "marionnaud.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "nocibe.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "notino.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "mademoiselle-bio.com" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "parfumdo.com" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "amazon.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "beautysuccess.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "leclercdrive.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "makeup.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : ( store.name === "monoprix.fr" ? [
              ...Array.from(new Set(categories)).map((l) => ({
                label: l,
                  value: l.replace(store.prefix, ""),
              })),
            ] : null))))))))))}
            onChangeCategories={setCatFilter}
          />
        </Col>
      </Row>
    </>
  );
}
