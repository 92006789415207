import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Buffer } from "buffer";
import AWS from "aws-sdk";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Login.css";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker.jsx";
import { Button, Modal } from "react-bootstrap";
const cookies = new Cookies();
const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
AWS.config.update({ region: "us-east-1" });
const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

var rand = function () {
  return Math.random().toString(36).substr(2); // remove `0.`
};

var token = function () {
  return rand() + rand(); // to make it longer
};

function geTime() {
  var d = new Date();
  var options = {
    timeZone: "Europe/Paris",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  var dd = d.toLocaleDateString("fr-FR", options);
  var splited = dd.split(" ");
  var newd = splited[0].split("/").reverse().join("-");
  var newDate = newd + " " + splited[1];
  return newDate;
}
export default function Login({ callOnLogin }) {
  const navigate = useNavigate();

  const [loginParams, setLoginParams] = useState({
    username: "",
  });
  function handleFormSubmit(event) {
    event.preventDefault();
    setLoginParams({
      username: event.target[0].value,
    });
  }
  const getJSON = async (url) => {
    const response = await fetch(url);
    if (!response.ok)
      // check if response worked (no 404 errors etc...)
      throw new Error(response.statusText);

    const data = response.json(); // get JSON from the response
    return data; // returns a promise, which resolves to this data value
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const toka = searchParams.get("token");
  const eml = searchParams.get("email");
  const toki = cookies.get("myToken");
  if (toka === toki) {
    getJSON("https://scrfiles.s3.amazonaws.com/emailsDict.json")
      .then((data2) => {
        let dict = JSON.stringify(data2);
        var newDate = geTime();
        var n = dict.lastIndexOf("]");
        var str =
          dict.substring(0, n) +
          ',{"' +
          eml +
          '": "' +
          newDate +
          '"}' +
          dict.substring(n);
        var buf = Buffer.from(str);
        const s3 = new AWS.S3({
          accessKeyId: ACCESS_KEY_ID,
          secretAccessKey: SECRET_ACCESS_KEY,
        });
        var data = {
          Bucket: "scrfiles",
          Key: "emailsDict.json",
          Body: buf,
          ContentEncoding: "base64",
          ContentType: "application/json"
        };

        s3.upload(data, function (err) {
          if (err) {
            console.log("");
          } else {
            localStorage.setItem(
              "token",
              "IAMj4Dv35Jn=?c=2i?W2vI1G5ts/IEknD1djcWNHyoPIgu7PSvrYs/1gwo.1337"
            );
            cookies.set("usrEmailbeauty", eml, {
              path: "/",
              expires: new Date(Date.now() + 3600 * 1000 * 87660),
            });
            callOnLogin(true);
            navigate("/");
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      getJSON("https://scrfiles.s3.amazonaws.com/emailsDict.json").then(
        (data2) => {
          var keys = [];
          var exist = false;
          for (let i = 0; i < data2.length; i++) {
            keys.push(Object.keys(data2[i]));
          }
          for (let i = 0; i < keys.length; i++) {
            if (keys[i].includes(values.email)) {
              exist = true;
              break;
            }
          }
          if (exist) {
            handleShow1();
          } else {
            let token2 = token();
            cookies.set("myToken", token2, { path: "/" });
            const options = {
              method: "GET",
              url: "https://server-liard.vercel.app/sendmail",
              params: { email: values.email, token: token2 },
            };

            axios
              .request(options)
              .then((response) => {
                handleShow2();
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      );
    },
  });
  function verifyLogin() {
    const tokin = cookies.get("usrEmailbeauty");
    const username = loginParams;
    if (!tokin !== username.username) {
      getJSON("https://scrfiles.s3.amazonaws.com/emailsDict.json").then(
        (data2) => {
          let dict = JSON.stringify(data2);
          let emailexists = dict.indexOf('"' + username.username + '"') !== -1;
          if (emailexists) {
            localStorage.setItem(
              "token",
              "IAMj4Dv35Jn=?c=2i?W2vI1G5ts/IEknD1djcWNHyoPIgu7PSvrYs/1gwo.1337"
            );
            cookies.set("usrEmailbeauty", username.username, {
              path: "/",
              expires: new Date(Date.now() + 3600 * 1000 * 87660),
            });
            callOnLogin(true);
            navigate("/");
          } else if (!emailexists && username.username !== "") {
            handleShow();
          }
        }
      );
    } else if (tokin === username.username) {
      localStorage.setItem(
        "token",
        "IAMj4Dv35Jn=?c=2i?W2vI1G5ts/IEknD1djcWNHyoPIgu7PSvrYs/1gwo.1337"
      );
      callOnLogin(true);
      navigate("/");
    }
  }

  useEffect(() => {
    if (!!localStorage.getItem("token")) navigate("/");
    const username = loginParams;
    if (username) verifyLogin();
  }, [loginParams]);
  const gaEventTracker = useAnalyticsEventTracker("login");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="leftone col-4">
          <form onSubmit={formik.handleSubmit} className="login">
            <h3 style={{ fontSize: 15 }}>
              Don't have an account? Enter your email to sign up
            </h3>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <span style={{ color: "red" }}>{formik.errors.email}</span>
              )}
            </div>
            <button
              type="submit"
              formNoValidate
              className="btn btn-dark btn-lg btn-block mt-4"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="col-4">
          <form onSubmit={handleFormSubmit} className="login">
            <h3 style={{ fontSize: 16 }}>Log in</h3>
            <div className="form-group">
              <label>Email</label>
              <input type="username" name="username" className="form-control" />
            </div>
            <button
              type="submit"
              onClick={() => gaEventTracker("login")}
              className="btn btn-dark btn-lg btn-block mt-4"
            >
              Sign in
            </button>
          </form>
          <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Try again please
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show1}
            onHide={handleClose1}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              email already exist
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              please check your email
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
